import Styles from "./SeneteWalletPage.module.css";
const SeneteWalletPage = () => {
  return (
<>
    <div className={Styles.container}>
      <div className={Styles.box} >
      <img src="nexaLogo.png" alt="Nexa Stream"></img>
      </div>
    
    <p>Coming Soon</p>
   
    
    </div>
    </>
  );
};
export default SeneteWalletPage;
